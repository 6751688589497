import { Injectable } from '@angular/core';
import { Conversation, ConversationMessage, ConversationUserProfile } from './chat.types';
import { BehaviorSubject, map, Observable, switchMap, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Tenant, TenantsService } from 'src/app/core/tenants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  
  private apiUrl = environment.api.tenantPortalApiBaseUrl + 'conversations';
  private tenantId: string;
  
  private _conversationUserProfile: BehaviorSubject<ConversationUserProfile> = new BehaviorSubject<ConversationUserProfile>(null);
  private _conversationMessages: BehaviorSubject<ConversationMessage[]> = new BehaviorSubject<ConversationMessage[]>([]);

  constructor(private _http: HttpClient,
    private _tenantsService: TenantsService
  ) {
    this.tenantId = environment.tenantId;

    this._tenantsService.tenant$
		.subscribe((tenant: Tenant) => {
      this.tenantId = tenant.id;
		});
  }

  get conversationUserProfile$(): Observable<ConversationUserProfile> {
    return this._conversationUserProfile.asObservable();
  }

  get conversationMessages$(): Observable<ConversationMessage[]> {
    return this._conversationMessages.asObservable();
  }

  getAllMessages(clientId: string): Observable<Conversation> {
    return this.conversationMessages$.pipe(
      take(1),
      switchMap((messages) => {
        return this._http.get<Conversation>(`${this.apiUrl}/clients/${clientId}?tenantId=${this.tenantId}`).pipe(
          tap((response) => {
            this._conversationMessages.next(response.messages);
            this._conversationUserProfile.next({ fullName: response.fullName });
          }),
        );
      })
    );
  }

  sendMessages(body: any[]): Observable<any> {
    return this._http.post(`${this.apiUrl}/clients/sendbatch?tenantId=${this.tenantId}`, body);
  }

  sendMessage(clientId: string, messageInputText: string) {
    var body = {
      messageText: messageInputText,
      clientId: clientId
    };
    return this._http.post(`${this.apiUrl}/clients/${clientId}/send?tenantId=${this.tenantId}`, body);
  }

  sendMessageForConversation(clientId: string, conversationId: string, messageInputText: string) {
    var body = {
      messageText: messageInputText,
      clientId: clientId,
      conversationId: conversationId
    };
    return this._http.post(`${this.apiUrl}/clients/${clientId}/send?tenantId=${this.tenantId}`, body);
  }

  pushNewMessages(messages: ConversationMessage[]): Observable<ConversationMessage[]> {
    return this.conversationMessages$.pipe(
      take(1),
      map((cachedMessages) => {
        const messageMap = new Map(cachedMessages.map(msg => [msg.id, msg]));
        messages.forEach(msg => messageMap.set(msg.id, msg));
        const newMessages = [...messageMap.values()];
        this._conversationMessages.next(newMessages);
        return newMessages;
      })
    );
  }
}
